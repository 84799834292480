.section1
{
    margin-top: 150px;
    padding-left:80px;
    padding-right:80px;
}
.sectionone-flx
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.desk-txt1
{
    font-weight: 800;
    font-size:60px;
    color:#484848;
    line-height: 80px;

}
.demo-btn
{
    
    background: #208fe1;
    display: inline-block;
    padding: 12px 30px 12px 30px;
    margin-top: 15px;
    color: white !important;
    
    border-radius: 5px;

}
.txt{
font-size: 20px;
color:#484848;
}


@media(max-width:900px)
{
    .section1 {
        margin-top: 85px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .deskbox-img
    {
        padding-top:35px;
    }
    .desk-txt1 {
        font-weight: 800;
        font-size: 30px;
        color: #484848;
        line-height: 43px;
    }
    .txt {
        font-size: 18px;
        color: #484848;
    }
}