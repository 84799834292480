.container1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    gap: 30px;
    position: fixed;
    top: 0;
    width: 82.5%;
    z-index: 99;
    background-color: white;

}

.search-inp {
    width: 380px !important;
    border-radius: 8px !important;
    height: 36px;
    border: 1px solid #e7e7e7;
    padding: 10px;
    outline: none;
    font-size: 13px;

}

.search_design {
    align-items: baseline;

}

.input-group .btn {
    position: relative;
    right: 41px;
    z-index: 14;
    font-size: 14px;
    border: none;
}

.icons {
    display: flex;
    align-items: center;
}

.icon {
    margin-left: 10px;
    cursor: pointer;
}

.square-btn {
    padding-left: 20px;
    padding-right: 20px;
    height: 32px;
    background-color: #E687FD;
    border: none;
    border-radius: 5px;
    margin-right: 0px;
    color: #fff;
    font-size: 14px;
    cursor: default !important;

}

.circle-btn {
    width: 32px;
    height: 32px;
    background-color: #007bff;
    border: none;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    font-size: 14px;
    padding-right: 1px;

}

.circle-btnname {
    width: 40px;
    height: 40px;
    background-color: #007bff;
    border: none;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    font-size: 14px;
    padding-right: 1px;

}

.icon1 {
    border: none;
    background: transparent;
    font-size: 16px;
    margin-right: 14px;
}

.user-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    padding-right: 1px;
    border-left: 2px solid rgb(206, 206, 206);
    margin-left: 15px;
}

.user-txt {
    font-size: 16px;
    margin-bottom: 1px;
    font-weight: 400;
    text-align: right;

}

.user-txt1 {
    color: rgb(134, 133, 133);
    font-size: 14px;
    text-align: right;

    margin-bottom: 0px;
}

.top-side-border {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    padding-left: 1px;
    /* border-right: 2px solid rgb(206, 206, 206); */
    margin-left: 15px;
    padding-right: 0px;
    margin-right: 0px;
}

@media(max-width:1100px) {

    .search-inp {
        width: 270px !important;
        border-radius: 8px !important;
        height: 36px;
        border: 1px solid #e7e7e7;
    }

    .container1 {
        width: 100%;
        position: static;
    }
}

@media(max-width:1000px) {
    .container1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        gap: 10px;
        position: static;
        width: 100%;
    }

    .search-inp {
        width: 180px !important;
        border-radius: 8px !important;
    }

    .side-pad {
        padding-left: 15px;
    }

}

@media(max-width:800px) {
    .helpbot {
        display: none;
    }

    .icon1 {
        border: none;
        background: transparent;
        font-size: 20px;
        margin-right: 9px;
    }

    .container1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        flex-wrap: wrap;
        gap: 10px;
    }
}

@media (max-width:600px) {
    .container1 {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        flex-wrap: wrap;
        gap: 16px;
    }

    .search-inp {
        width: 100%;
    }

    .user-txt1 {
        color: rgb(134, 133, 133);
        font-size: 12px;
        text-align: right;
        margin-bottom: 0px;
    }

    .user-txt {
        font-size: 16px;
        margin-bottom: 1px;
        font-weight: 600;
        text-align: right;
    }
}