.sdnav2 {
    width: 85%;
    /* background-color: rgb(143, 19, 102); */

    float: right;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-top: 4.5rem;
}

.dtl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding-top: 10px;
    border-top: 0.5px solid #00000045;
}

.pra1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pra2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pra3 {
    flex-direction: column;
    display: flex;
    align-items: center;
}

.custom-spinner {
    width: 3rem;
    height: 3rem;
}

.blackcolor {
    color: black;
    text-decoration: none;
}
.dashbord-option > .inner-padding {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 110px;
}