.edit-btn {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.subscription .scroll_table-space {
    max-height: 51vh;
    overflow: scroll;
    scrollbar-width: none;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
    padding: 0px;
    position: relative;
    border:1px solid #e8e8e8;
}

.left-clickbtn {
    background-color: white;
    height: 30px;
    width: 30px;
    border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mypage1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.page-flx1 {
    flex-wrap: wrap;
    gap: 15px;
}

.subscription_table>.tablebord {
    padding: 0px;
    box-shadow: none;
}

.sss {
    display: inline-block;
}

.text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}


.table-container {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}


.relodbtnsub {
    height: 40px;
    width: 50px;
}

.myload {

    position: absolute;
    width: 80%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.drpon {
    height: 37px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(212, 212, 212);
    outline: none;
}

.customSelect {
    /* width: 200px; */
    border: none;
    padding: 0;
    padding: .375rem 2.25rem .375rem .75rem;
    display: block;

    /* width: 100%; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    width: 100%;
    margin-top: 0.1rem;
    list-style: none;
    background-color: white;
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown-menu li {
    padding: 0.5rem;
    cursor: pointer;
}

.dropdown-menu li:hover {
    background-color: #007bff;
}

.abluebutton {
    height: 39px !important;
    width: 39px !important;
    background-color: #0b9de8;
    color: white;
    border-radius: 8px;
}

.sd2drop>.dropdown {
    width: 100%;
}

.sd2drop {

    width: 100%;
}

.nextdsupport {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* gap: 10px; */
    margin-left: 0px;
    margin-top: -12px;
}

.subscription_table .middledata .nodatafound1 {
    text-align: center;
}

.subscription_table td:first-child,
th:first-child {
    width: 100px;
}

.subscription_table table thead {
    position: sticky;
    top: 0;
}
.sub1{
    width:25%;
    padding-left:2px;
}
.sub2{
    width:25%
}
.v3{
    width:20%
}

@media(max-width:1400px) {
    .subscription .scroll_table-space {
        max-height: 48vh;
        overflow: scroll;
        scrollbar-width: none;
        /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
        padding: 0px;
        position: relative;
    }
}