.roleClass>.cabins-card {
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    padding: 20px 0px 20px 0px;
    margin-bottom: 40px;
    border-radius: 5px;
    /* background-color: red; */
    position: relative;
}

.roleClass>.cabins-card>.cabin-sideline {
    height: 10px;
    width: 20px;
    background-color: #007bff;
    position: absolute;
    top: 0px;
    right: 0px;
    /* border-radius: 10px 0px 0px 10px; */
}


.cabin-number {
    height: 50px;
    width: 50px;
    border: 2px solid #007bff;
    color: #007bff;
    background-color: white;
    border-radius: 50%;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
}

.botflx-cabin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.cabin-editicn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
}

.roleClass .option-edit-delete {
    position: absolute;
    width: 100px;
    float: right;
    flex-direction: column;
    align-items: self-end;
    right: 0;
    top: 20px;
    padding: 0px 60px;
}