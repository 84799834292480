* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #208FE1 !important;
  --primaryShade-bgColor: #208FE1 !important;
  --primaryShade-Color: #208FE1 !important;
}

html,
body {
  overflow-x: hidden;
}

.image-modal-slider .slick-prev {
  left: 10px;
}

.image-modal-slider .slick-prev::before,
.image-modal-slider .slick-next::before {
  color: gray;
}

.image-modal-slider .slick-next {
  right: 10px;
}


.Inclusive-login-page {
  position: relative;
  /* width: 100vw; */
  height: calc(100vh - 0px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDF0F7;

  margin-top: 0px;
}

a {
  cursor: pointer;
}

.login-big-wrapper {
  width: 90%;
  max-width: 1100px;
  height: 90%;
  border-radius: 9px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  object-fit: cover;
  overflow: hidden;
}

.section-wrapper {
  width: 50%;
  padding: 2.6rem 4rem;
}

.login-banner-section {
  /* background-color: var(--primary-color); */
  border-radius: 0 9px 9px 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.otpsent
{
  margin-bottom: 22px;
  font-size: 16px;
}
.login-txt
{

  margin-bottom: 10px!important;
}
@media only screen and (max-width: 860px) {
  .login-banner-section {
    display: none;
  }
.login-txt
{
  font-size: 24px!important;
  margin-bottom: 10px!important;
}
.log-inlogo
{
  margin-bottom: 10px;
}
  .section-wrapper {
    max-width: 580px;
    width: 100%;
    margin: 0 auto;
    padding: 1.7rem 10vw;
  }

  .login-big-wrapper {
    width: 95%;
    padding: 2.2rem 0;
    height: 50%;
  }
    /* Adjust height of .login-big-wrapper when OTP field is visible */
    .login-big-wrapper.show-otp {
      width: 95%;
      padding: 2.2rem 0;
      height: 80%;
    }
}

@media (max-width:576px) {
  .login-big-wrapper {
    width: 95%;
    padding: 0;
    height: 60%;
  }

  .section-wrapper {
    padding: 1rem !important;
  }
  .Inclusive-login-page {
    position: relative;
    /* width: 100vw; */
    height: calc(91vh - 0px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EDF0F7;
    margin-top: 0px;
}
}

.top-suggest_register {
  font-size: 0.8rem;
}
.subbmitbtn
{
  background: #208fe1;
}

.top-suggest_register span {
  color: #ABB8c5;
  margin-right: 8px;
}

.top-suggest_register a {
  font-size: 0.7rem;
  color: rgb(13, 16, 163);
  text-decoration: none;
  font-weight: 600;
  
}

.top-login-explain {
  margin-top: 1.3vw;
}

.top-login-explain h2 {
  
  font-weight: bold;
  margin-bottom: 1.4rem;
  font-size: 2rem;
  
}

.top-login-explain p {
  color: #afafaf;
  
  font-size: 0.7rem;
}

.login-big-wrapper form {
  position: relative;
  padding-top: 1.3rem;
}

.login-big-wrapper form label {
  position: absolute;
  top: -10px;
  left: 14px;
  font-size: 0.8rem;
  
  background-color: white;
  color: #646464;
  padding: 0 6px;
}

.login-big-wrapper form .input-wrapper {
  position: relative;
}

.login-big-wrapper form input {
  outline: 0;
  width: 100%;
  margin-bottom: 0.3rem;
  padding: 18px;
  padding-top: 22px;
  border: #dadcdd solid 1px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #646464;
}

.loginPass {
  margin-bottom: 0rem;
  

}

.login-big-wrapper form input:focus-within {
  border: var(--primary-color) solid 1px;
}

.login-big-wrapper form input:focus+label {
  color: var(--primary-color) !important;
}

.login-screen__forgotpassword {
  float: right;
  text-decoration: none;
  
  font-size: 0.7rem;
  margin-top: 8px;
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
  padding: 3px 6px;
}

.login-big-wrapper form button {
  cursor: pointer;
  border: none;
  outline: 0;
  /* background-color: var(--primary-color); */
  width: 100%;
  text-align: center;
  color: white;
  padding: 15px;
  border-radius: 6px;
  margin-top: 25px;
  letter-spacing: 0.4px;
}

.error_message {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  margin-top: -1.2rem;
  background-color: rgba(245, 72, 72, 0.9);
  color: white;
  padding: 8px;
  font-size: 0.9rem;
  text-align: center;
}
.log-inlogo
{
  height:30px;
  margin-right: -15px;
}

.try_again{
  padding-top: 1.3rem;
  color:#000000;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.privacy_and_policy_and_terms{
  cursor: pointer;
}
.try_again_as_privacy_and_policy_and_terms{
  padding-top: 1.3rem;
  margin-bottom: -10px;
  color:#000000;
  text-align: center;
  font-size: 14px;
}

.hover_class_change
{
  color:#0606ff;
  

}
.otprnter-txt
{
  margin-bottom: 6px !important;
}