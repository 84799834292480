.edit-btn {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

th td {
    font-size: 14px;
}

.left-clickbtn {
    background-color: white;
    height: 30px;
    width: 30px;
    border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mypage1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.page-flx1 {
    flex-wrap: wrap;
    gap: 15px;
}

.suport>.tablebord {
    padding: 0px;
    box-shadow: none;
}

.action a {
    color: white;
}

.action {
    width: 150px !important;
}

.sss {
    display: inline-block;
}

th {
    /* width: 300px; */
    white-space: nowrap;
    text-align: center;
    text-transform: capitalize;
}

.text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

td:not(:last-child) {
    width: 300px;
    white-space: nowrap;
}

td:last-child {
    width: 100px;
    white-space: nowrap;
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}

.relodbtn {
    height: 40px;
    width: 77px;

}

.dropon {
    height: 37px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(212, 212, 212);
    outline: none;
}

.relodbtnsub {
    height: 40px;
    width: 50px;
}

.myload {

    position: absolute;
    width: 80%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.nextdsupport {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin-left: 15px;
}

.support_table .scroll_table-space {
    max-height: 51vh;
    overflow: scroll;
    scrollbar-width: none;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
    padding: 0px;
    position: relative;
}


.support_table .middledata .nodatafound1 {
    text-align: center;
}

.support_table td:first-child,
th:first-child {
    width: 100px;
}

.support_table table thead {
    position: sticky;
    top: 0;
}
@media(max-width:1400px) {
   
.support_table .scroll_table-space {
    max-height: 48vh;
    overflow: scroll;
    scrollbar-width: none;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
    padding: 0px;
    position: relative;
}
}