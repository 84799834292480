/* CSS for the tabs */
.tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #918989;
}

.tab {
  cursor: pointer;
  padding: 10px 20px;
  /* border: 1px solid #ced4da; */
  /* border-bottom: none; */
  /* background-color: #f8f9fa; */
  border-radius: 5px 5px 0 0;
  margin-right: 5px;
  z-index: 0;
}

.tab:last-child {
  margin-right: 0;
}

.tab.active {
  /* background-color: #fff; */
  border: 1px solid #918989;
  background-color: #f8f9fa;
  border-bottom: none;
  z-index: 111;
  border-color: #ced4da #ced4da #fff;
}

/* CSS for the form */
.modal-body {
  padding: 20px;
}
