.event_apply_modal .modal-body {
    height: 60vh;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.event_apply_modal .time-slot-item {
    cursor: pointer;
    margin-bottom: 10px;
}

.event_apply_modal .time-slot-card {
    /* border: 1px solid #ccc; */
    /* border-radius: 5px; */
    /* padding: 10px; */
    /* background-color: #f9f9f9; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

.event_apply_modal .time-slot-time {
    font-size: 18px;
    font-weight: bold;
}


.event_apply_modal .selected {
    background-color: #28a745;
    /* Green background color */
    color: #fff;
    /* White text color */
    border-color: #28a745;
    /* Matching border color */
}

.event_apply_modal .noSelected {
    background-color: red;
    /* Green background color */
    color: #fff;
    /* White text color */
    border-color: red;
    /* Matching border color */
}

/* Additional styling for the card layout */
.event_apply_modal .card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    transition: all 0.3s ease;
}

.event_apply_modal .text-danger {
    color: green !important;
}

.event_apply_modal .card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.event_apply_modal .card-body {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.select_date {
    font-size: xx-small;
}

.date_scroll {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    overflow-x: scroll;
    scroll-behavior: smooth;
    overflow: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.data_row {
    width: 220px;
}

.date_card {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.date_scroll .selected {
    background-color: #28a745;
    color: #fff;
    border-color: #28a745;
    padding: 0px !important;
}