.privacy-top
{
    margin-top:0px;
    background: #f4f4f99e;
    padding: 30px;
}
.privacy-txt
{
    font-size: 15px;
    color:black;
    font-weight: 500;
    text-align: justify;
}
.privacy-head
{
    margin-top: 30px;
    margin-bottom: 20px;
}
.privacy-head-1
{
    margin-top: 30px;
    margin-bottom: 20px;
font-size: 40px;

}