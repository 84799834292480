.sec1tion21
{
    margin-top: 70px;
}
.deskbox-feature1
{
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 0px;
}

.deskbox-txt21
{
    font-size: 45px;
    color: #484848;
    font-weight: 600;
}
.deskbox-desc2
{
    font-size: 15px;
    color: #484848;
    font-weight: 300;
}
.deskbox-feature-flx
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:20px;
    margin-top:20px ;
}
.feature-txt11
{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 3px;
}
.feature-txt21
{
    font-size: 15px;
    font-weight: 500;
    color:#484848;
}
.feature-icn1
{
    background-color: #208fe1;    ;
    color:white;
    padding: 11px 9px 11px 11px;
   
    font-size: 18px;
    border-radius: 50%;
    

}
.flx12
{
    padding-top:11px;
}
.feature-top02 {
    /* margin-top: 50px; */
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.feature-top2
{
    margin-top:120px;
}
.feature-img2
{
    padding-right: 40px;
}
.feature-padd2
{
    padding-left: 40px;

}
.sec1tion2
{
    margin-top:40px;
}
@media (max-width:1500px)
{
    .feature-top02
{
    margin-top:50px;
    padding-left:7%;
    padding-right:7%;
    display: flex;
    justify-content: center;
    align-items: center; 
    
}
}
@media (max-width:800px)
{
    .feature-padd {
        padding-left: 10px;
    }
    .feature-img2
{
    padding-right: 10px;
    padding-top:20px;
    padding-bottom:30px;
}
.feature-top02 {
    /* margin-top: 50px; */
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.sec1tion2 {
    margin-top: 12px;
}
.feature-padd2 {
    padding-left: 10px !important;
}
}