a {
  margin: 0;
  padding: 0;
}
.sidenav {
  height: 100vh;
  /* Default width for desktop */
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  background-color: #2b2b2b;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
  width: 17.5%;
  padding-left: 10px;
  padding-right: 10px;
  scrollbar-width: none;
  /* Default width for desktop */
}
.sidenav.open {
  width: 17.5%; /* Default width for screens wider than 1100px */
  z-index: 15;
}

.dropdown-options {
  margin-left: 10px;
  max-height: 0;
  overflow: hidden;
  /* display: none; */
  transition: max-height 0.4s ease-in-out, transform 0.4s ease-in-out;
  transform: translateX(-20px); /* Adjusted translate for vertical alignment */
}

.dropdown-options.open {
  transition: max-height 0.8s ease-in-out, transform 0.8s ease-in-out;
  transform: translateX(
    0
  ); /* Bring the dropdown back to its original position */
  max-height: 500px;
}
.pages i {
  transition: transform 0.3s ease-in-out 0.1s; /* Transition for icon with a slight delay */
}

.pages.open i {
  transform: rotate(180deg);
  transition-delay: 0.2s; /* Ensure the icon animation starts immediately when opening */
}

.pages.active {
  background-color: rgba(255, 255, 255, 0.122);
  color: white;
  border-radius: 0px;
  border-left: 5px solid #38b6ff;
}

.pages.open {
  background-color: rgba(255, 255, 255, 0.122);
  color: white;
  border-radius: 0px;
  border-left: 5px solid #38b6ff;
}

.pages1.active {
  background-color: rgba(255, 255, 255, 0.122);
  color: white;
  border-radius: 0px;
  border-left: 5px solid #38b6ff;
}
.sidenav a {
  padding: 13px 8px 13px 10px;
  text-decoration: none;
  font-size: 14px;
  color: white;
  display: block;
  transition: 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  border-left: 5px solid transparent;
}
span {
  margin: 0;
  padding: 0;
  display: inline;
}
.rgt {
  float: right;
  margin-right: 15px;
}

.sidenav a:hover {
  background-color: rgba(255, 255, 255, 0.122);
  color: white; /* Text color */
  border-radius: 0px;
  border-left: 5px solid #38b6ff;
}
.mstr {
  margin-right: 15px;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  display: none;
}

.side-txt {
  font-size: 17px;
  color: white;
  margin-bottom: 1px;
  margin-top: 2px;
}

.side-txt1 {
  font-size: 15px;
  color: white;
  margin-bottom: 0px;
  color: #cacaca;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.side-flx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 18px;
  margin-top: 25px;
  margin-left: 15px;
}

.profile {
  height: 50px;
}

.pages {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  font-size: 15px;
}
.pages1 {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  margin-left: 12px;
  margin-right: 0px;
  /* background-color: rgba(255, 255, 255, 0.122); */
}

.pages2 {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  gap: 14px;
  font-size: 15px;
  padding-right: 20px !important;
}

.top-marginn {
  margin-top: 25px;
  height: 87vh;
  overflow: scroll;
  scrollbar-width: none;
}

.logo-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 10px;
}

.desk-flx {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.logo-flx2,
.logo2 {
  display: none;
}

.dropdown-content {
  background-color: #2b2b2b;
  color: white; /* Text color */
  border-radius: 5px;
  margin-left: 20px;
  position: static;
  animation-delay: 2s;
}
.fillicn {
  font-size: 7px;
}

@media screen and (max-width: 1100px) {
  .sidenav {
    width: 0;
    padding-left: 0px;
    padding-right: 0px;
    scrollbar-width: none;
  }
  .sidenav.open {
    width: 30%;
  }
  .logo-flx2,
  .logo2 {
    display: block;
  }
  .logo2 {
    display: block;
    height: 30px;
  }
  .logo-flx2 {
    padding-right: 8px;
  }
}
@media screen and (max-width: 970px) {
  .logo2 {
    display: block;
    height: 30px;
  }
}

@media screen and (max-width: 900px) {
  .sidenav {
    width: 0;
    /* On smaller screens, hide the sidenav by default */
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .sidenav.open {
    width: 30%;
  }

  .sidenav .closebtn {
    position: absolute;
    top: -11px;
    right: 10px;
    font-size: 30px;
    margin-left: 50px;
    display: block;
  }

  .desk-flx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 768px) {
  .sidenav {
    width: 0;
    /* On smaller screens, hide the sidenav by default */
    padding-top: 15px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .sidenav.open {
    width: 50%;
  }

  .sidenav .closebtn {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 30px;
    margin-left: 50px;
    display: block;
  }

  .desk-flx {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 14px;
    padding-left: 15px;
  }
}

@media screen and (max-width: 590px) {
  .sidenav {
    padding-top: 15px;
  }
  @media screen and (max-width: 590px) {
    .sidenav.open {
      width: 80%;
    }
  }
  .sidenav a {
    font-size: 18px;
  }
}
