.section02
{
    margin-top: 80px;
}
.deskbox-feature
{
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0px;
}
.deskbox-line
{
    height:2px;
    background-color: black;
    color: black;
    border:none;
    width:80px;
    margin-top:8px;
}
/* .deskbox-txt2
{
    font-size: 35px;
    color: #484848;
    font-weight: 600;
    
} */
.deskbox-desc
{
    font-size: 16px;
    color: #484848;
    font-weight: 500;
    
}
.deskbox-feature-flx
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:20px;
    margin-top:20px ;
}
.feature-txt1
{
    font-size: 17px;
    /* font-weight: 750; */
    margin-bottom: 3px;
    color:#484848;
    
}
.feature-txt2
{
    font-size: 14px;
    font-weight: 600;
    margin-top:16px;
    color:#616161;
    
}
.feature-icn
{
    background-color: #208fe1;    ;
    color:white;
    padding: 11px 9px 11px 11px;
    ;
    font-size: 16px;
    border-radius: 50%;
    

}
.flx2
{
    padding-top:8px;
}
.feature4-top
{
    margin-top:50px;
    padding-left:5%;
    padding-right:5%;
    display: flex;
    justify-content: center;
    align-items: center; 
    
}
.feature-img
{
    padding-left: 40px;
}
.feature-padd
{
    padding-right: 40px;

}
.desk-top
{
    margin-top: 36px;
    margin-left: 15px;
}
.one-flex
{
    height:90px;
    width:90px;
    background-color: #575757;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.feature1-icn
{
    font-size: 30px;
    color:white;
}
@media (max-width:800px)
{
    .feature-padd {
        padding-right: 10px;
    }
    .feature-img
{
    padding-left: 10px;
    padding-top:20px;
}
.section02 {
    margin-top: 30px;
}
}