@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.dashboardfont {
  font-family: "Public Sans", sans-serif;
}

.tpp {
  margin-top: 100px;
}

.profile-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image-circle img {
  width: 100%;
  height: auto;
}

.heading {
  font-weight: bold;
  margin-bottom: 10px;
}

label {
  font-weight: bold;
  margin: 10px 0 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.col_parent_class {
  padding: 0;
  margin: 0;
}

.second_child_width {
  width: 82.5%;
}

.first_child_width {
  width: 17.5%;
}

.side-pad {
  padding-left: 0px;
}

.featured-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.main_heading1 {
  font-size: 30px;
  font-weight: 400;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  /*margin-left: 90px;*/
}

.horizontalline {
  height: 4px;
  width: 130px;
  background-color: #007bff;
  border: none;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  opacity: 1;
}

.location-top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.circle-btn-add {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  font-size: 17px;
  padding-right: 1px;
}

.location-top2 {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
  gap: 10px;
}

.dropbtn {
  color: black;
  padding: 7px;
  font-size: 22px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  /* display: inline-block; */
  width: 100%;
}

.drop-width {
  position: relative;
  display: inline-block;
  width: auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/

.location-top2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 0px;
}

.featured-flx1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.space-txt {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
}

.main-location-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px 15px 20px 15px;
  margin-bottom: 26px;
  border-radius: 5px;
  /* background-color: red; */
}

.view-btn {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  margin-right: 0px;
  color: #fff;
  float: right;
}

.22inner-padding {
  padding-left: 40px;
  padding-right: 40px;
  /* padding-top: 90px; */
}

.circle-btn-A {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: 9px;
}

.view-btn-2 {
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  border: 1px solid #007bff;
  color: #007bff;

  border-radius: 5px;
  margin-right: 0px;
  /* color: #fff; */
  float: right;
  font-size: 14px;
  margin-right: 10px;
}

.location-txt {
  margin-top: 7px;
  font-size: 14px;
}

/* dashboardcss */
.dashboard-card {
  padding: 15px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  color: black;
  border-radius: 8px;
  height: 200px;
  margin-bottom: 20px;
  /* background: red; */
}

.dashboard-card:hover {
  background-color: #007bff;
  color: white;
  transition: 0.8s;
}

.des-icn {
  font-size: 25px;
}

.dashboard-flx {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.dash-data {
  font-size: 28px;
  margin-bottom: 0px;
}

.space-data {
  font-size: 20px;
  margin-bottom: 0px;
}

.space-data1 {
  font-size: 15px;
  margin-bottom: 0px;
  color: rgb(148, 148, 148);
}

.botflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.view-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-size: 15px;
  padding-left: 9px;
}

.pencil-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-size: 15px;
  padding-left: 9px;
  margin-left: 5px;
}

.editicn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trash-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  font-size: 15px;
  padding-left: 9px;
}

.view-icn:hover,
.view-btn-2:hover,
.trash-icn:hover,
.pencil-icn:hover {
  background-color: #007bff;
  color: white;
  transition: 0.7s;
}

.dash-data1 {
  font-size: 17px;
  margin-bottom: 0px;
}

.dash-line {
  margin: 0px;
  width: 60%;
  margin-top: 8px;
  margin-bottom: 8px;
}

.dashboard_secondcard {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-direction: column;
  margin-top: 18px;
}

.resource-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 15px;
}

.resource-card {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.resource-sheet {
  background-color: rgb(242, 242, 242);
  padding: 20px;
}

.cabin-sheet {
  padding-left: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 15px;
  gap: 20px;
}

.cabin-number {
  height: 50px;
  width: 50px;
  border: 2px solid #007bff;
  color: #007bff;
  background-color: white;
  border-radius: 50%;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
}

.cabins-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px 0px 20px 0px;
  margin-bottom: 40px;
  border-radius: 5px;
  /* background-color: red; */
  position: relative;
}

.total-number {
  height: 90px;
  width: 90px;
  border: 2px solid #007bff;
  color: #007bff;
  background-color: white;
  border-radius: 50%;
  font-size: 27px;
}

.resource-sheet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.botflx-cabin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.cabin-space-txt {
  margin-bottom: 0px;
  font-size: 21px;
  font-weight: 500;
}

.cabin-totalseat {
  margin-bottom: 0px;
  font-size: 19px;
  font-weight: 500;
}

.totalseat {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 600;
}

.more-btm {
  display: flex;
  justify-content: flex-end;
}

.dropdownborder {
  border: none;
}

.show {
  border: none;
  outline: none;
}

.show.active {
  border: none;
  outline: none;
}

.dropdown-menu {
  background-color: #f0f0f0;
  color: white;
}

.card-top {
  margin-top: 5px;
}

.resource-sider {
  padding: 10px 0px 10px 10px;
}

.droptop {
  margin-top: 10px;
}

.search-container {
  position: relative;
  display: inline-block;
}

.search-bar {
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease, border-bottom 0.3s ease;
  /* Added border-bottom transition */
  padding: 10px;
  border: none;
  background: transparent;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom: 2px solid transparent;
  /* Initially transparent border bottom */
  outline: none;
  /* Remove outline */
  padding-right: 60px;
}

.search-bar.expanded {
  width: 300px;
  /* Adjust this width as needed */
  border-bottom-color: #d1d1d1;
  /* Change border bottom color when expanded */
}

.search-button {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background-color: #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.page-flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.page-txt1 {
  margin-bottom: 0px;
  font-size: 16px;
}

.button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: white;
  color: black;
  border: 1px solid #cdcdcd;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button.active {
  background-color: #007bff;
  color: white;
}

.page-flx {
  margin-bottom: 40px;
}

th,
td {
  padding: 12px !important;
  text-align: left;
}

.edt-flx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.edt-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  padding-left: 9px;
}

/* .view-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  font-size: 12px;
  padding-left: 9px;
  margin-left: 10px;
} */

.dlt-icn {
  padding: 4px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;

  padding-left: 6px;
}

.tables-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.location-select {
  height: 40px;
  padding-left: 8px;
  padding-right: 30px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: rgb(255, 255, 255);
}

.select-upr {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  outline: none;
  padding-left: 0px;
  padding-right: 3px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.side-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  margin-bottom: 30px;
}

.post-card {
  position: relative;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  margin-bottom: 20px;
}

.over-dropdown {
  position: absolute;
  top: -7px;
  right: -18px;
}

.more-detail {
  color: white;
  font-size: 17px;
}

.space-card {
  padding: 15px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  color: black;
  border-radius: 8px;
  height: 250px;
  margin-bottom: 20px;
  /* background: red; */
}

.space1-icn {
  height: 40px;
  width: 40px;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

.space_secondcard {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  flex-direction: column;
  margin-top: 18px;
}

.space-btn-2 {
  padding-left: 12px;
  padding-right: 12px;
  height: 40px;
  border: 1px solid #007bff;
  color: #007bff;
  margin-top: 16px;
  border-radius: 5px;
  margin-right: 0px;
  /* color: #fff; */
  float: right;
  font-size: 14px;
  margin-right: 10px;
}

.space-icn {
  font-size: 18px;
}

.space1-txt1 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10px;
}

.space-circle-btn-A {
  width: 40px;
  height: 40px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  margin-top: 0px;
}

.space1-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  padding: 20px 15px 20px 15px;
  margin-bottom: 40px;
  border-radius: 5px;
  height: 250px;
}

.keybord-icn {
  font-size: 22px;
}

.cabin-sideline {
  height: 10px;
  width: 20px;
  background-color: #007bff;
  position: absolute;
  top: 0px;
  right: 0px;
  /* border-radius: 10px 0px 0px 10px; */
}

.cabin-sideline1 {
  height: 40px;
  width: 40px;
  background-color: #007bff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 0px 90px 0px 0px;
  /* border-radius: 10px 0px 0px 10px; */
}

.cabin-editicn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 15px;
}

.cafitarea-card {
  height: 120px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  border-left: 8px solid #007bff;
  position: relative;
}

.cafit-txt {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 500;
}

.botflx-cafit {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* adjust the opacity as needed */
  z-index: 1040;
  /* ensure the backdrop is below the modal */
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  /* ensure the modal is above the backdrop */
}

.posts-title {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 6px;
}

.posts-txt {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
}

.post-inner-txt {
  padding: 10px 15px 10px 15px;
}

.posts-public {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.post-sideline1 {
  height: 38px;
  width: 38px;
  background-color: #007bff;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 0px 0px 0px 90px;
  /* border-radius: 10px 0px 0px 10px; */
}

.comment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.postcount {
  margin-bottom: 0px;
  margin-top: 1px;
  font-size: 14px;
  color: rgb(99, 99, 99);
}

.likeicn {
  font-size: 17px;
  /* color:rgb(164, 164, 164); */
  color: rgb(99, 99, 99);
}

.post-image {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.slt-txt {
  font-size: 16px;
  margin-bottom: 0;
}

.select-data1 {
  gap: 20px;
}

.input-top {
  margin-top: 7px;
  font-size: 15px;
  border-radius: 0px;
}

.tab-txt1 {
  font-size: 15px;
}

.form-side {
  margin-left: 20px;
  margin-right: 20px;
}

.modalhead {
  /* background:#f3f3f3; */
  padding: 11px;
  padding-left: 15px;
}

.filter-box {
  background-color: rgb(245 245 245);
  padding: 10px;
  padding-top: 9px;
  padding-bottom: 0px;
  margin-bottom: 40px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  position: relative;
}

.filter-sideline {
  height: 10px;
  width: 40px;
  background-color: #007bff;
  position: absolute;
  top: 0px;
  right: 0px;
  /* border-radius: 10px 0px 0px 10px; */
}

.select-top {
  margin-top: 10px;
}

.custom-select {
  position: relative;
  width: 100%;
  /* Customize width as needed */
  background-color: white;
}

.select-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 30;
  list-style: none;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  font-size: 14px;

  transition: visibility 0s, opacity 0.2s linear;
}

.select-options li {
  padding: 10px;
  cursor: pointer;
}

.select-options li:hover {
  background-color: #f0f0f0;
}

.custom-select.open .select-options {
  visibility: visible;
  opacity: 1;
}

.modalclose {
  background: #007bff;
  z-index: 10;
  position: absolute;
  right: 7px;
  height: 37px;
  width: 37px;
  border-radius: 0px 10px 0px 90px;
  opacity: 1;
  font-size: 20px;
  color: white;
}

.closeicn11 {
  position: absolute;
  top: 6px;
  right: 11px;
}

.form-headingdata {
  font-size: 16px;
  color: black;
}

.seatdesk {
  height: 30px;
  width: auto;
  background: #007bff;
  display: flex;
  align-items: center;
  justify-content: flex;
  padding-left: 17px;
  padding-right: 17px;
  border-radius: 5px;
}

.seatdesk-count {
  height: 35px;
  width: auto;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  font-size: 14px;
  color: white;
}

.seatdesk-editicn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  /* margin-top: 15px; */
}

.seatdeskpencil-icn {
  padding: 5px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 14px;
  padding-left: 6px;
  padding-top: 6px;
}

.seatdesktrash-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 14px;
  padding-left: 9px;
  padding-top: 9px;
}

.seatdeskpencil-icn:hover,
.seatdesktrash-icn:hover,
.floor-btn-2:hover,
.floor-trash-icn:hover,
.floor-pencil-icn:hover,
.floor-view-icn:hover {
  background-color: #007bff;
  color: white;
  transition: 0.7s;
}

.seatdesk-sheet {
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
}

.seatdesk-totalseat {
  margin-bottom: 1px;
  font-size: 19px;
  font-weight: 500;
  margin-left: 15px;
}

.seatdesk-desc-totalseat {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  margin-left: 15px;
  margin-bottom: 15px;
}

.type-of-cabin {
  font-size: 14px;
  font-weight: 400;
  /* margin-left: 15px; */
}

.seatbox-txt {
  margin-bottom: 4px;
  font-size: 15px;
  margin-left: 0px;
  font-weight: 500;
}

.box-back {
  background-color: #f3f3f3;
  padding: 10px 8px 10px 10px;
}

.seatdesk-card {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 20px 0px 0px 0px;
  margin-bottom: 25px;
  border-radius: 5px;
  /* background-color: red; */
  position: relative;
}

.seatbox-desc {
  margin-bottom: 5px;
  font-size: 13px;
  margin-left: 0px;
}

.rgt-bdr {
  border: none;
  border-right: 1px solid #cbcbcb;
}

.floordesk-sheet {
  padding-left: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  gap: 20px;
}

.floor-botflx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  padding: 0px 10px 0px 10px;
}

.floor-btn-2 {
  padding-left: 12px;
  padding-right: 12px;
  height: 32px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  /* color: #fff; */
  float: right;
  font-size: 14px;
  margin-right: 0px;
}

.floor-trash-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 15px;
  padding-left: 9px;
  padding-top: 10px;
}

.floor-pencil-icn,
.floor-view-icn {
  padding: 8px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  font-size: 15px;
  padding-left: 9px;
  padding-top: 10px;
}

.floor-pencil-icn {
  margin-left: 10px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  /* backdrop-filter: blur(2.5px); */
}

.nav-tabs {
  border-radius: 0px;
  border: none;
  padding: 5px;
  padding-bottom: 0px;

  background: #f3f3f3;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: #007bff;
  border: none;
  border-bottom: 2px solid rgb(59, 59, 59);
  /* border-right: 1px solid blue; */

  color: #007bff;
  border-radius: 0px;
}

.nav-tabs .nav-link {
  /* background-color: white; */

  color: #007bff;
  margin-left: 10px;
  border-radius: 5px;
}

/* Tooltip text */
.space1-txt1 .tooltiptext {
  visibility: hidden;
  width: fit-content;
  background-color: rgba(67, 66, 66, 0.933);
  color: #fff;
  text-align: center;
  padding: 3px 5px;
  border-radius: 6px;
  text-wrap: nowrap;
  font-size: 16px;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -50%;
  opacity: 0;
  transition: opacity 0.4s;
}

.space1-txt1:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.space1-txt1 .tooltiptext::after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(67, 66, 66, 0.933) transparent;
}

.active-btn {
  padding-left: 20px;
  padding-right: 20px;
  height: 37px;
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: 5px;

  font-size: 14px;
  margin-right: 10px;
}

.active-btn.active {
  background-color: #007bff;
  /* Background color when active */
  color: white;
}

.event-select-top {
  margin-top: 10px;
}

.total-cabin1 {
  height: 90px;
  width: 90px;
  float: right;
  background-color: #007bff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 25px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.center_seater {
  display: flex;
  justify-content: center;
  align-items: center;
}

.occupancy-sheet {
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  gap: 20px;
}

.manzoku {
  background: radial-gradient(circle closest-side, transparent 66%, white 0), conic-gradient(#4e79a7 0, #4e79a7 80%, #f28e2c 0, #f28e2c 100%);
  position: relative;

  min-height: 150px;
  margin: 0;
}

.manzoku p.right {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
}

.manzoku p.right span {
  font-size: 10px;
}

.manzoku p.left {
  position: absolute;
  top: 30%;
  left: 40%;
}

/* team */
.team-cards {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  padding: 6px;
  margin-bottom: 25px;
}

.team-image {
  height: 75px;
  width: 75px;
  object-fit: cover;
  border-radius: 8px;
}

.active1-dot {
  position: absolute;
  top: 60px;
  left: 63px;
  background-color: white;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-top: 2px;
}

.red-active1 {
  font-size: 15px;
  color: green;
  height: 20px;
  width: 20px;
  padding-bottom: 2px;
}

.team-totalseat {
  margin-bottom: 1px;
  font-size: 16px;
  font-weight: 500;
  margin-left: 3px;
  margin-top: 7px;
}

.team-desc-totalseat {
  font-size: 14px;
  font-weight: 400;
  margin-left: 3px;
  margin-bottom: 5px;
}

.invoice {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  padding: 20px;
  /* max-width: 800px; */
  margin: 0 auto;
}

.header {
  /* border-bottom: 1px solid #ccc; */
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.header h2 {
  margin: 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
}

.table th,
.table td {
  border: 1px solid #ccc;
  padding: 8px;
}

.footer {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.footer p {
  margin: 5px 0;
}

.card {
  box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 1rem;
}

@media (max-width: 1100px) {
  .second_child_width {
    width: 100%;
  }

  .first_child_width {
    width: 100%;
  }
}

@media (max-width: 990px) {
  .second_child_width {
    width: 100%;
  }

  .first_child_width {
    width: 100%;
  }

  .dash-data {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .dash-data1 {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .inner-padding {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 40px;
  }

  .search-bar.expanded {
    width: 190px;
    /* Adjust this width as needed */
    border-bottom-color: #d1d1d1;
    /* Change border bottom color when expanded */
    background-color: white;
  }
}
