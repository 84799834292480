.edit-btn {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

th td {
    font-size: 14px;
}

.left-clickbtn {
    background-color: white;
    height: 30px;
    width: 30px;
    border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mypage1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.page-flx1 {
    flex-wrap: wrap;
    gap: 15px;
}

.tablebord {
    padding: 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.sss {
    display: inline-block;
}

td {
    width: 200px;
    white-space: nowrap;
}
.table-container {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
}
.dropon
{
    height:37px;
    width:100%;
    border-radius: 5px;
    border:1px solid rgb(212, 212, 212);
outline:none;
}

.relodbtnsub {
    height: 40px;
    width: 50px;
}

.myload {

    position: absolute;
    width: 80%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}
.edit-btn1
{
    height:35px;
    width:35px;
    color:white;
    background-color: #208fe1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 15px;
}
.delete-btn1
{
    height:35px;
    width:35px;
    color:white;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 15px;
}
.mybots
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
}
thead>tr{
    cursor: pointer;
}