.card {
    border-radius: 15px;
}

.card-header {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.message-text {
    border-radius: 17px 17px 17px 0px;
    padding: 10px;
}

.bg-light {
    background-color: rgba(57, 192, 237, .2);
}

.bg-white {
    background-color: #fbfbfb;
}

.bg-image {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
}

.bg-image img {
    border-radius: 15px;
    width: 100%;
}

.mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.mask:hover {
    opacity: 1;
}

.form-outline {
    margin-top: 20px;
}

textarea {
    resize: none;
}
.scroll_chat{
    max-height:40vh;
    overflow:scroll;
    overflow-x: hidden;
    scrollbar-width: none;
}

.reply_msg
{
    background-color: #edeeee;
    border-radius: 17px 17px 0px 17px;
    max-width: 90%;
    
}
.first-rply
{
    background-color: #edeeee;
    max-width: 90%;
    margin-right: 15px;
}
.type-text
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px 0px 10px 0px;
}
.delet_icn1
{
    color:white;
    float:right;
    height: 30px;
    width: 30px;
    background-color: #208fe1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding-left: 2px;
}
.create-type
{
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-direction: column; */
    gap:15px;
    margin-top: 10px;
    
    
}
.create-type1
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column ;
    gap:4px;
    /* padding-top: 10px; */

    
    
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 3px;
    bottom: 3.5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .iconscenter{
    display: flex;
    justify-content:center;
    align-items: center;
  }