.section21
{
    margin-top: 70px;
}
.deskbox-feature1
{
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 0px;
}


.deskbox-desc2
{
    font-size: 15px;
    color: #484848;
    font-weight: 300;
}
.deskbox-feature-flx
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:20px;
    margin-top:20px ;
}
.feature-txt11
{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 3px;
}
.feature-txt21
{
    font-size: 15px;
    font-weight: 500;
    color:#484848;
}
.feature-icn1
{
    background-color: #208fe1;    ;
    color:white;
    padding: 11px 9px 11px 11px;
   
    font-size: 18px;
    border-radius: 50%;
    

}
.flx12
{
    padding-top:11px;
}
.feature-top2
{
    margin-top:120px;
}
.feature-img2
{
    padding-right: 40px;
}
.feature-padd2
{
    padding-left: 40px;

}
@media (max-width:800px)
{
    .feature-padd {
        padding-left: 10px;
    }
    .feature-img2
{
    padding-right: 10px;
    padding-top:20px;
}
}