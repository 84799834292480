.scrolling_modal
{
    overflow-y: scroll;
    height: 70vh;
    scrollbar-width: none;
}
.modal
{
    background-color: rgba(124, 124, 124, 0.819);
    /* backdrop-filter: blur(1.1px);  */
    transition: 0.7s;
}


.mydrop_hgt {
    height: 200px;
    overflow: auto;
    position: absolute !important;
    z-index: 9199 !important;
    border-radius: 0px !important;
}