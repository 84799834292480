.contact-section
{
    background: linear-gradient(to bottom, #efefef47 0%, #ededed 100%);
    margin-top:3%;
    padding-top: 40px;
    padding-bottom: 70px;
    color:black;
    position: relative;
}
.pricing-line1 {
    height: 2px;
    background-color: rgb(0, 0, 0);
    color: rgb(8, 8, 8);
    border: none;
    width: 80px;
    margin-top: 8px;
}
.form-group {
    margin-bottom: 20px; /* Adjust this value as needed */
  }
  .form-top
  {
    margin-top: 30px;
  }
  .feald-hgt
  {
    height:50px;
  }
  
  .ct1-img
  {
    height:150px;
    position: absolute;
    top:0px;
    left:0px;
  }
  .ct2-img
  {
    height:100px;
    position: absolute;
    top:20px;
    right:10px;
  }
  .ct3-img
  {
    height:100px;
    position: absolute;
    bottom:20px;
    left:10px;
  }
  .call-bot
  {
    height:40px;
    padding-left: 50px;
    padding-right: 50px;
    border:none;
    border-radius: 8px;
    color:white;
    background-color: #208fe1;
  }
  .error-border {
    border: 1px solid red;
}
  .resize1
  {
    resize: none;
  }
  @media(max-width:800px)
  {
    .ct2-img, .ct3-img
    {
        display: none;
    }
  }