.section2
{
    margin-top: 50px;
}
.deskbox-feature
{
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 0px;
}
.deskbox-line
{
    height:2px;
    background-color: black;
    color: black;
    border:none;
    width:80px;
    margin-top:8px;
}
.deskbox-txt2
{
    font-size: 35px;
    color: #484848;
    font-weight: 700;
    letter-spacing: 1px;
    
}
.deskbox-descri
{
    font-size: 16px;
    color: #484848;
    font-weight: 500;
    margin-bottom: 1vh;
    
}
.deskbox-feature1-flx
{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:20px;
    margin-top:8px ;
    width:100%;
}
.flx2
{
    width:9vh;
}
.flx3
{
    width:91vh;
}
.feature-txt1
{
    font-size: 17px;
    font-weight: 700;
    margin-bottom: 3px;
    color:#484848;
    letter-spacing: 1px;
    
}
.feature1-txt2
{
    font-size: 14px;
    font-weight: 600;
    margin-top:1vh;
    color:#616161;
    letter-spacing: 1px;
    
}
.feature-icn
{
    background-color: #208fe1;    ;
    color:white;
    padding: 11px 9px 11px 11px;
    ;
    font-size: 16px;
    border-radius: 50%;
    

}
.flx2
{
    padding-top:8px;
}
.feature1-top
{
    margin-top:50px;
    padding-left:10%;
    padding-right:10%;
    display: flex;
    justify-content: center;
    align-items: center; 
    
}
.feature-img
{
    padding-left: 40px;
}
.feature-padd
{
    padding-right: 40px;

}
.desk-top-2
{
    margin-top: 4vh;
    margin-left: 15px;
}
.one-flex
{
    height:90px;
    width:90px;
    background-color: #575757;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.feature1-icn
{
    font-size: 30px;
    color:white;
}
@media (max-width:1500px)
{
    .feature1-top
{
    margin-top:50px;
    padding-left:7%;
    padding-right:7%;
    display: flex;
    justify-content: center;
    align-items: center; 
    
}
}
@media (max-width:970px)
{
    .feature1-top {
        margin-top: 35px !important;
        padding-left: 15px!important;
        padding-right: 15px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
}
@media (max-width:860px)
{
    .feature-padd {
        padding-right: 10px;
        margin-top: 20px;
    }
    .flx2
{
    width:8vh;
}
.flx3
{
    width:92vh;
}
    .feature-img
{
    padding-left: 0px !important;
    padding-top:0px !important;
    padding-bottom:10px;
}

.deskbox-feature {
    font-size: 28px !important;
    font-weight: 600;
    margin-bottom: 0px;
}
.deskbox-txt2 {
    font-size: 25px !important;
    color: #484848;
    font-weight: 600;
}
.desk-top {
    margin-top: 36px;
    margin-left: 1px !important;
}
.feature-txt1 {
    font-size: 17px;
    font-weight: 600 !important;
    margin-bottom: 3px;
    color: #484848;
}
.feature1-txt2 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 6% !important;
    color: #7c7c7c !important;
}
.desk-top-2 {
    margin-top: 4vh;
    margin-left: 0px;
}
}
@media (max-width:500px)
{
    .flx2
    {
        width:12vh;
    }
    .flx3
    {
        width:88vh;
    }
}