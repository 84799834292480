.custom-spinner {
  width: 3rem;
  height: 3rem;
}
.blackcolor
{
  color:black;
  text-decoration: none;
}

.line1
{
  margin-left: 1px;
}