.table {
    margin-bottom: 0px;
}

.client_table .table-responsive {
    padding: 0;
}

.client_table .edit-btn {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.client_table .left-clickbtn {
    background-color: white;
    height: 30px;
    width: 30px;
    border: 1px solid blue;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client_table .mypage1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.client_table .scroll_table table {
    border-collapse: collapse;
    margin-top: 1px;
    background: #ddd;
    margin-bottom: 0px;
}

.client_table .scroll_table table tbody tr td th {
    /* border: 0px solid #ddd; */
    border-bottom: 0px solid #ddd;
    padding: 8px;
}


.client_table td:first-child,
th:first-child {
    width: 100px !important;
}

.client_table thead {
    position: fixed;
}

.client_table .scroll_table table thead {
    position: sticky;
    top: 0;
    z-index: 2;
    border: none;
}

th:first-child {
    width: 103px !important;
    border-top: none;

    background-color: rgb(232, 234, 237);
}

th:not(:nth-child(1)) {
    border-top: none;
    background-color: rgb(232, 234, 237);
}

.client_table .page-flx1 {
    flex-wrap: wrap;
    gap: 15px;
}

.client_table .sss {
    display: inline-block;
}

.client_table td,
th {
    width: 200px;
    white-space: nowrap;
}

.client_table .table-container {
    width: 100%;
    overflow-x: auto;
}

.client_table table {
    width: 100%;
    border-collapse: collapse;
}


.client_table .dropon {
    height: 37px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgb(212, 212, 212);
    outline: none;
}

.client_table .relodbtnsub {
    height: 40px;
    width: 50px;
}

.client_table .myload {
    position: absolute;
    width: 80%;
    height: 70vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
}

.client_table .myload1 {
    position: fixed;
    top: 47%;
    left: 50%;
    z-index: 40;
}

.custom-spinner {
    width: 3rem;
    height: 3rem;
}

.blackcolor {
    color: black;
    text-decoration: none;
}



.client_table .edit-btn1 {
    height: 35px;
    width: 35px;
    color: white;
    background-color: #208fe1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 15px;
}

.client_table .delete-btn1 {
    height: 35px;
    width: 35px;
    color: white;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 15px;
}

.client_table .mybots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.client_table thead>tr {
    cursor: pointer;
}

.client_table .middledata .nodatafound1 {
    text-align: center;
}

.client_table .scroll_table {
    max-height: 64vh;
    overflow: scroll;
    scrollbar-width: none;
    /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
    position: relative;
    border-top: 1px solid white;
    border: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
}

.client_table .fixx1 {
    position: fixed;
}

.client_table thead {
    border: 1px solid black;
    position: fixed;
    border: none !important;
}

.new2 .featured-flx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
    margin-bottom: 15px;
    margin-top: 10px;
}

table {
    border-bottom: none;
}

.scroll_table .client_table {
    border-bottom: none;
}

.mknk label {
    font-weight: normal;
}

.desk-refresh {
    height: 38px;
    background-color: #0d6efd;
    color: white;
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 5px;
    position: relative;
}

.abs {
    position: absolute;
    top: 10px;
    left: 15.5px;
}

.hidd {
    visibility: hidden;
}

.scrollbody {
    position: relative;
}

/* .ri-add-line {
   
    margin-left: 1px;
} */
@media (max-width: 1400px) {
    .scroll_table {
        height: 56vh;
        overflow: scroll;
        scrollbar-width: none;
        /* box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px; */
        /* padding: 10px; */
    }

    html,
    body {
        overflow-y: scroll !important;
    }

    .client_table thead tr {
        margin-top: 10px;
        background-color: #ddd;
    }

    th:first-child {
        border-top: none;
        top: -2px;
        background-color: rgb(232, 234, 237);
    }

    th:not(:nth-child(1)) {
        border-top: none;
        top: -2px;
        background-color: rgb(232, 234, 237);
    }

}

@media(max-width:800px) {
    .scroll_table {
        height: 46vh;
        overflow: scroll;
        scrollbar-width: none;
    }

    .srch>.search-inp {
        width: 100% !important;
        margin-top: 20px;
    }
}